.footer {
    background-color: #131313;
    color: white;
    padding: 20px; /* Adjust padding as needed */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: -10px;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .content-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items to the start of the container */
    width: 100%;
    padding: 0 0px; /* Adjust padding as needed */
    box-sizing: border-box;
  }
  
  .gas-container {
    margin-left: 40px;
    display: flex;
    align-items: center;
  }
  
  .gas-fee-text {
    font-size: 12px; /* Adjust font size to match the size of the icon */
    margin-left: 10px; /* Adjust the gap between gas fee icon and text */
  }
  
  .social-icons {
    margin-top: 5px;
    margin-right: 100px;
    display: flex;
    align-items: center;
    gap: 25px; /* Adjust the gap between icons */
  }
  
/* Adjustments for mobile */
    @media (max-width: 768px) {
    .content-container {
      flex-direction: row; /* Display items in a row */
      justify-content: space-between; /* Space out the items */
      align-items: center; /* Align items vertically */
    }

    .footer {
      bottom: 5px;
    }
  
    .gas-container {
      align-self: flex-start; /* Align gas fee container to the top */
      margin-right: auto; /* Move gas fee container closer to the left */
      margin-left: 25px;
    }
  
    .social-icons {
      margin-top: 0; /* Remove top margin */
      margin-left: auto; /* Move social icons closer to the right */
      margin-right: 75px;
    }
  }