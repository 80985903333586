.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 25px; /* Adjust the padding to create space on the left */
  background-color: #131313;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 5px;
  left: 0;
  z-index: 1000;
  height: 70px; /* Set a fixed height for the header */
  font-family: 'Poppins', sans-serif; /* Set font to Poppins */
}

.nav {
  display: flex;
  align-items: center;
}

.logo {
  height: 70px;
  width: auto;
  margin-right: 30px;
  cursor: pointer; /* Set cursor to pointer */
}

.menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0px;
  align-items: center;
  cursor: pointer; /* Set cursor to pointer */
}

.menu li {
  margin: 0 10px; /* Adjust margin between menu items */
}

.menu li h1 {
  display: block;
  padding: 15px; /* Adjust padding to increase space between menu items and around the text */
  color: white;
  text-decoration: none;
  font-size: 15px; /* Adjust font size as needed */
}

.menu li h1:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Change background color on hover */
  border-radius: 10px; /* Add border radius for a rounded look */
}

.wallet-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px; /* Adjust the margin-right value to move the wallet container more to the right */
  min-width: 140px;
  cursor: default; /* Set cursor to pointer */
}

.wallet-info {
  font-size: 80%;
  margin-right: 20px;
  cursor: default; /* Set cursor to pointer */
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button {
  width: 140px;
  padding: 10px;
  border: 1px solid #1a1a1a;
  border-radius: 5px;
  color: white;
  background-color: #1a1a1a;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
}

button:hover {
  background-color: #2c2c2c; /* Change background color on hover */
  color: white; /* Change text color to black on hover */
}

/* Adjustments for mobile */
@media (max-width: 768px) {
  .header {
    padding: 10px 15px; /* Reduce padding */
    padding-left: 40px;
  }

  .menu {
    display: none; /* Hide menu by default on smaller screens */
    position: absolute;
    top: 70px;
    left: 0;
    background-color: #131313;
    width: 100%;
    padding: 0px 0;
  }

  .menu.show {
    display: flex; /* Show menu when toggled */
    flex-direction: column;
    align-items: center;
  }

  .menu li {
    text-align: center;
  }

  .menu li a {
    display: block;
    color: white;
    text-decoration: none;
    border-bottom: 1px solid #2c2c2c; /* Add border to the bottom of each menu item */
  }

  .menu li:first-child a {
    border-top: 1px solid #2c2c2c; /* Add border to the top of the first menu item */
  }

  .hamburger {
    display: block; /* Show on smaller screens */
    cursor: pointer;
  }

  .line {
    width: 20px;
    height: 3px;
    background-color: white;
    margin: 3px;
  }
}
