/* HomeComponent.css */
.home-title {
  text-align: center;
  font-size: 32px;
}

.home-container {
  text-align: center;
}

.centered-button {
  margin-bottom: 40px;
}