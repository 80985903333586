/* App.css */
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: sans-serif;
  background-color: #131313;
  color: white;
}

.title {
  text-align: center;
  width: 100%;
  font-size: 26px; /* Adjust the font size as needed */
  font-family: 'Poppins', sans-serif; /* Set font to Poppins */

}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}